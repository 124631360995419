import { css } from 'styled-components';
import * as svars from 'style/variables';

export default css`
  .react-datepicker {
    font-size: 1.1em;
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.fontColorBase};
    border: ${({ theme }) => theme.searchCard.border};
    border-radius: ${({ theme }) => theme.card.borderRadius};
    /* Box shadow that adds a white shadow effect on the whole screen around the datepicker */
    box-shadow: 0 0 0 100vmax rgba(255, 255, 255, 0.5);
  }
  .react-datepicker__input-container {
    display: flex;
    align-items: center;
  }
  .react-datepicker__header {
    background: white;
  }
  .react-datepicker__month {
    margin: 0.4em 0.1rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.6em;
    line-height: 1.6em;
    padding: 0.3em;
    margin: 0;
  }
  .react-datepicker-wrapper {
    margin: ${svars.spaceMedium} auto;
  }
  .react-datepicker-popper {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -55%) !important;
  }
  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.fontColorPrimary};
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.fontColorBase};
  }
  .react-datepicker__month-container {
    min-height: 326px;
  }
`;
