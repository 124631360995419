import { forwardRef, useMemo } from 'react';
import commonPropTypes from 'tools/commonPropTypes';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import * as svars from 'style/variables';
import campaignCommonConfigurationService from 'services/configuration.service';
import styled from 'styled-components';
import { BiCalendar } from 'react-icons/bi';

const DATEPICKER_MAX_WIDTH = '200px';

const StyledReactDatePicker = styled(ReactDatePicker)`
  outline: none;
  cursor: pointer;

  border: none;
  font-size: ${svars.fontSizeXLarge};
  width: ${DATEPICKER_MAX_WIDTH};
`;

const DateInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  transition: ${svars.transitionBase};
  white-space: nowrap;
  :hover {
    color: ${({ theme }) => theme.primary};
  }
  &:active {
    color: ${({ theme }) => theme.primary};
  }
`;

function DateInput({ value, onClick, className }, ref) {
  const displayValue = value || '__ / __ / ____';
  return (
    <DateInputContainer className={className} onClick={onClick} ref={ref}>
      <BiCalendar
        style={{
          cursor: 'pointer',
          fontSize: svars.fontSizeBig,
          padding: 0,
          paddingInlineEnd: svars.spaceMedium,
        }}
      />
      {displayValue}
    </DateInputContainer>
  );
}

DateInput.propTypes = {
  value: commonPropTypes.string,
  onClick: commonPropTypes.func,
  className: commonPropTypes.string,
};
DateInput.defaultProps = {
  value: '',
  onClick: () => {},
  className: '',
};

function DateQuestion({ focusNext, question, questionValue, answer }) {
  const { language } = campaignCommonConfigurationService;
  const [minDate, maxDate] = useMemo(
    () => [
      question.min_date ? new Date(question.min_date) : null,
      question.max_date ? new Date(question.max_date) : null,
    ],
    [question.min_date, question.max_date]
  );
  const answerWithDate = (date) => {
    if (date) {
      // Remove the timezone offset to get the correct date
      // This is necessary because the date is returned in UTC
      const goodDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      answer(goodDate.toISOString().split('T')[0]);
      focusNext();
    } else {
      answer(null);
    }
  };
  const ExampleCustomInput = forwardRef(DateInput);

  return (
    <>
      <StyledReactDatePicker
        selected={questionValue ? new Date(questionValue) : ''}
        onChange={answerWithDate}
        minDate={minDate}
        maxDate={maxDate}
        customInput={<ExampleCustomInput className="example-custom-input" />}
        locale={language}
      />
    </>
  );
}

DateQuestion.propTypes = {
  ...commonPropTypes.surveyQuestionPropTypes,
  question: commonPropTypes.dateQuestion,
};

export default DateQuestion;
