import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { selectIsOpen, setIsOpen } from 'store/search';

import { logEvent } from 'app/analytics';
import SearchCard from 'components/ui/clickable/SearchCard';
import { getAllSearchParams, pushWithSearchQuery } from 'helpers';
import Mark from 'mark.js';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import ontologyService from 'services/ontology.service';
import * as svars from 'style/variables';
import { useKeypress, useOnClickOutside } from 'tools/hooks';

import FadeInOut from './animation/FadeInOut';
import SearchBar from './input/SearchBar';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${svars.spaceSmall} 0;
  overflow: hidden;
  height: 100%;
`;

const SearchCardListContainer = styled.div`
  height: 100%;
  overflow: clip auto;
`;

const Search = ({
  onClick,
  style,
  initialSearchComponent,
  emptySearchComponent,
  children,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [urlSearchQuery, setUrlSearchQuery] = useState('');
  const [initialSearch, setInitialSearch] = useState(true);
  const { noRootNoNAOntology, ontologyIndex } = ontologyService;

  const [searchOptions, setSearchOptions] = useState(noRootNoNAOntology);
  const searchIsOpen = useSelector(selectIsOpen);
  const { search } = feedbackCampaignService.wording;
  const ref = useRef();
  const inputRef = useRef();

  const toggleResults = useCallback(
    () => dispatch(setIsOpen(!searchIsOpen)),
    [searchIsOpen, dispatch]
  );
  const onCloseResults = useCallback(() => {
    if (searchIsOpen) {
      if (history.location.search.includes('query')) {
        setUrlSearchQuery('');
        pushWithSearchQuery('/feedback', history, {}, ['query']);
      }
      toggleResults();
      inputRef.current.blur();
      setInitialSearch(true);
    }
  }, [history, searchIsOpen, toggleResults]);
  useOnClickOutside(ref, onCloseResults);
  useKeypress('Escape', onCloseResults);
  useEffect(() => {
    if (
      history.location.search !== urlSearchQuery &&
      history.location.search.includes('query')
    ) {
      const newQuery = `${getAllSearchParams(history.location.search).query} `;
      if (newQuery) {
        setQuery(newQuery);
        if (!searchIsOpen) {
          dispatch(setIsOpen(true));
          setUrlSearchQuery(history.location.search);
        }
      }
    }
  }, [history.location?.search, searchIsOpen, urlSearchQuery, dispatch]);
  useEffect(() => {
    if (initialSearch && searchIsOpen) setInitialSearch(false);
    let results = [];
    if (query.length) {
      const searched = ontologyIndex.search(query, { enrich: true });
      if (searched.length) {
        results = searched[0].result.map(({ doc }) => doc);
      } else {
        if (searchOptions.length) {
          // Log only when hitting the empty page
          logEvent({
            category: 'Search',
            action: 'empty results',
            label: query,
          });
        }
      }
    } else {
      results = noRootNoNAOntology;
    }
    setSearchOptions(results);
    const newMarkInstance = new Mark(document.querySelector('#search-node'));
    newMarkInstance.unmark({
      done: () => {
        newMarkInstance.mark(query.trim());
      },
    });
  }, [query, ontologyIndex, noRootNoNAOntology]); // eslint-disable-line
  return (
    <SearchContainer style={style} ref={ref}>
      <SearchBar
        placeholder={search.inputPlaceholder}
        ref={inputRef}
        value={query}
        onChange={({ target: { value } }) => {
          setQuery(value);
        }}
        fluid
        onFocus={() => !searchIsOpen && toggleResults()}
        showBackButton={searchIsOpen}
        goBack={() => {
          setQuery('');
          onCloseResults();
        }}
        data-testid="feedback-search-bar"
      />
      {searchIsOpen ? (
        <FadeInOut
          show={searchIsOpen}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          {!query.length && initialSearch ? (
            // Check if initialSearchComponent is a function before calling it
            typeof initialSearchComponent === 'function' ? (
              initialSearchComponent({
                goToGeneralFeedback: () => {
                  // general feedback receives a 'general' slug that will be redirected to the first parent concept
                  pushWithSearchQuery('/feedback/general', history);
                },
                keywordExamples: search.keyword_examples,
              })
            ) : null
          ) : searchOptions && searchOptions.length ? (
            <SearchCardListContainer id="search-node">
              {searchOptions.map(({ name, description, parentName, id }) => (
                <SearchCard
                  gaCategory="Search"
                  gaAction="click search result"
                  gaLabel={id}
                  onClick={() => {
                    onClick(id);
                  }}
                  key={`scard-${id}`}
                  label={name}
                  description={description}
                  categoryLabel={parentName}
                  testid={`feedback-search-card-${id}`}
                />
              ))}
            </SearchCardListContainer>
          ) : (
            typeof emptySearchComponent === 'function' &&
            emptySearchComponent({
              goToGeneralFeedback: () => {
                // general feedback receives a 'general' slug that will be redirected to the first parent concept
                pushWithSearchQuery('/feedback/general', history);
              },
              emptySearchQuery: () => setQuery(''),
            })
          )}
        </FadeInOut>
      ) : (
        children
      )}
    </SearchContainer>
  );
};

Search.propTypes = {
  onClick: PropTypes.func.isRequired,
  initialSearchComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  emptySearchComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  style: PropTypes.object,
};
Search.defaultProps = { focusOutComponent: null };

export default Search;
