import React from 'react';

import { createGlobalStyle } from 'styled-components';

import ReactDatePickerStyles from './datepicker';

import * as svars from 'style/variables';

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent;
    ${svars.thinScrollbarCss}
  }

  html,
  body, #root {
      height: 100%;
      margin: 0;
      padding: 0;
      font-size: ${({ theme }) => theme.fontSizeRoot};

  }
  body {
    background: ${({ theme }) => theme.appBackground};
    background-size: cover;
    background-position: center;
  }

  :root, button, textarea, a, input {
    font-weight: ${svars.fontWeightBase};
    font-family: ${({ theme }) => theme.fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.fontColorBase};
    padding: 0;
    outline-color: ${svars.fontColorLightest};
  }

  /* This is specific to rich text display */
  p {
    margin-right: 1px;
    margin-block: 0.3rem;
  }

  #root {
    margin: 0 auto;
    max-width: ${svars.rootMaxWidth};
    overflow: hidden;
    transition: opacity 1s ease-in-out;
  }

  mark {
    background:inherit;
    color: inherit;
    font-weight: ${svars.fontWeightBold};
  }
  input[type=search] {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${svars.colorGreyLight};
    border: 3px solid transparent;
    border-radius: 12px;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar {
    width: 16px;
  }

  ${ReactDatePickerStyles}
`;

export default React.memo(GlobalStyle);
