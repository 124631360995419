import { getAllSearchParams } from 'helpers';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { api } from 'app/api';
import * as Sentry from '@sentry/react';
import surveyCampaignService, {
  isMultichoiceQuestionType,
} from 'services/surveyCampaign.service';

export const TEXT_QUESTION_TYPE = 'TextSurveyQuestion';
export const DATE_QUESTION_TYPE = 'DateQuestion';
export const MULTICHOICE_QUESTION_TYPE = 'MultiChoiceSurveyQuestion';
export const RATING_QUESTION_TYPE = 'RatingQuestion';
export const NPS_QUESTION_TYPE = 'NPSQuestion';

const initializeState = () => ({
  loading: false,
  published: false,
  preview: false,
  answers: {},
  // Initialized to first authorized question from second question
  nextQuestionIndex: surveyCampaignService.getNextQuestionIndex({}, null),
  // Stack of the traversed questions - last element is the current question index
  // Used to go back to the previous questions bypassing logic execution
  // Initialized to first authorized question
  sessionStack: [surveyCampaignService.firstQuestionIndex],
  // Used to focus on the next button when the user clicks on the previous button
  // We use a number instead of a boolean to allow for resetting focus events when the user
  // changes its answer
  nextButtonFocused: 0,
  publishedId: null,
  endScreen: null,
});

const getQuestionAnswerType = (questionType) => {
  switch (questionType) {
    case TEXT_QUESTION_TYPE:
      return 'TextSurveyQuestionAnswer';
    case DATE_QUESTION_TYPE:
      return 'DateQuestionAnswer';
    case RATING_QUESTION_TYPE:
    case NPS_QUESTION_TYPE:
      return 'ScoredQuestionAnswer';
    case MULTICHOICE_QUESTION_TYPE:
      return 'MultiChoiceSurveyQuestionAnswer';
    default:
      return null;
  }
};

const formatSurveyAnswers = (answers) => {
  const { questions } = surveyCampaignService;
  const finalAnswers = [];
  for (const question of questions) {
    const answer = answers[question.id];
    const answerQualifies =
      answer != null &&
      surveyCampaignService.shouldDisplayWithConditions(
        answers,
        question?.conditions,
        questions
      );
    if (answerQualifies) {
      const isMultichoice = isMultichoiceQuestionType(question.type);
      const question_type = getQuestionAnswerType(question.type);
      finalAnswers.push({
        question: { id: question.id },
        question_type,
        value:
          (isMultichoice &&
            answer &&
            ((question.multiple && answer.map((value) => ({ id: value }))) || [
              { id: answer },
            ])) ||
          answer,
      });
    }
  }
  return finalAnswers;
};

export const publishSurvey = createAsyncThunk(
  'survey/publish',
  async (searchQuery, { getState }) => {
    const { answers } = getState().survey;
    const endScreen = surveyCampaignService.getEndScreen(answers);
    const payload = {
      date: new Date().toJSON(),
      query: getAllSearchParams(searchQuery),
      answers: formatSurveyAnswers(answers),
    };
    let response;
    try {
      response = await api.post('survey', payload);
    } catch (error) {
      Sentry.captureException(error);
    }
    return {
      ...(response?.data || {}),
      endScreen,
    };
    // return response ? response.data : response;
  }
);

const resetSurveyFromState = (state) => {
  const initialState = initializeState();
  state.published = initialState.published;
  state.preview = initialState.preview;
  state.sessionStack = initialState.sessionStack;
  state.questionIndex = initialState.questionIndex;
  state.answers = initialState.answers;
  state.nextQuestionIndex = initialState.nextQuestionIndex;
  state.nextButtonFocused = initialState.nextButtonFocused;
};

export const reviewSlice = createSlice({
  name: 'survey',
  initialState: initializeState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSurvey: (state) => {
      resetSurveyFromState(state);
    },
    setPreviewMode: (state, { payload }) => {
      state.preview = payload;
    },
    answerQuestion: (state, { payload: { id, value } }) => {
      state.answers[id] = value;
      state.nextQuestionIndex = surveyCampaignService.getNextQuestionIndex(
        state.answers,
        state.sessionStack[state.sessionStack.length - 1]
      );
    },
    next: (state) => {
      if (state.nextQuestionIndex !== surveyCampaignService.constructor.END) {
        state.sessionStack.push(state.nextQuestionIndex);
        state.nextQuestionIndex = surveyCampaignService.getNextQuestionIndex(
          state.answers,
          state.nextQuestionIndex
        );
        state.nextButtonFocused = 0;
      }
    },
    focusOnNextButton: (state) => {
      const questionAnswer =
        state.answers[
          surveyCampaignService.questions[
            state.sessionStack[state.sessionStack.length - 1]
          ].id
        ];
      // Focus on next button only if the user has already answered the question
      if (
        questionAnswer?.length ||
        (typeof questionAnswer === 'number' && state.answers != null)
      ) {
        state.nextButtonFocused += 1;
      }
    },
    previous: (state) => {
      if (state.sessionStack.length > 1) {
        state.nextQuestionIndex = state.sessionStack.pop();
        state.nextButtonFocused = 0;
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(publishSurvey.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.identified = false;
      })
      .addCase(publishSurvey.fulfilled, (state, action) => {
        state.publishedId = action.payload?.id;
        state.endScreen = action.payload?.endScreen;
        if (action.payload === 'Survey not sent') {
          state.preview = true;
        }
        state.loading = false;
        state.published = true;
        resetSurveyFromState(state);
      })
      .addCase(publishSurvey.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const {
  resetSurvey,
  setPreviewMode,
  answerQuestion,
  next,
  previous,
  focusOnNextButton,
} = reviewSlice.actions;

// Selectors
export const selectIsLastSurveyQuestion = (state) =>
  state.survey.nextQuestionIndex === surveyCampaignService.constructor.END;

export const selectLoading = (state) => state.survey.loading;
export const selectQuestionIndex = (state) =>
  state.survey?.sessionStack?.[state.survey.sessionStack.length - 1];
const selectAnswers = (state) => state.survey.answers;

export const selectQuestion = createSelector(
  selectQuestionIndex,
  (index) => surveyCampaignService.questions[index] || {}
);

export const selectSurveyHeaderTitle = createSelector(
  selectQuestion,
  // Survey header title is whether the section name of the question or the survey title
  (question) => question?.section_name || surveyCampaignService.title
);
export const selectQuestionValue = createSelector(
  selectQuestion,
  selectAnswers,
  (question, answers) => answers[question?.id]
);

export const selectNextIsAuthorized = createSelector(
  selectQuestion,
  selectQuestionValue,
  (question, value) => (question.required ? value != null : true)
);
export const selectNextButtonFocused = (state) =>
  state.survey.nextButtonFocused;

export const selectProgress = createSelector(
  (state) => state.survey.nextQuestionIndex,
  selectQuestionIndex,
  selectQuestionValue,

  selectNextIsAuthorized,
  (nextQuestionIndex, questionIndex, questionValue, nextIsAuthorized) => {
    const index =
      (nextIsAuthorized && questionValue && nextQuestionIndex) || questionIndex;
    const surveyLength =
      surveyCampaignService.questions?.length -
      surveyCampaignService.firstQuestionIndex;
    return (
      (index === surveyCampaignService.constructor.END && 1) ||
      (surveyLength &&
        (index - surveyCampaignService.firstQuestionIndex) /
          (surveyLength || 1)) ||
      0
    );
  }
);

export const selectEndScreen = (state) => state.survey.endScreen || {};

export const selectLastPublished = (state) => state.survey.publishedId;

export const selectPreview = (state) => state.review.preview;

export default reviewSlice.reducer;
