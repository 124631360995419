import PropTypes from 'prop-types';
import styled from 'styled-components';

import commonConfigurationService from 'services/configuration.service';
import * as svars from 'style/variables';

const SearchCardContainer = styled.button`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${svars.spaceMediumLarge});
  min-height: 3rem;
  appearance: none;
  padding: ${svars.spaceNormal} ${svars.spaceMedium};
  margin: ${svars.spaceMedium} ${svars.spaceNormal};
  border: 1px solid transparent;
  cursor: pointer;
  background: ${({ theme, selected }) =>
    selected
      ? theme.searchCard.selected.background
      : theme.searchCard.background};
  color: ${({ theme, selected }) =>
    selected ? svars.colorWhite : theme.fontColorBase};
  border-radius: ${({ theme: { searchCard } }) => searchCard.borderRadius};
  box-shadow: ${({ theme }) => theme.searchCard.boxShadow};
  border: ${({ theme }) => theme.searchCard.border};
  transition: all 0.15s ease-in-out;

  &:hover {
    color: ${({ theme, selected }) => selected || theme.searchCard.hover.color};
    background: ${({ selected, theme }) => selected || theme.primaryLightHover};
    box-shadow: ${({ theme }) => theme.searchCard.hover.boxShadow || 'initial'};
    border-color: ${({ theme }) =>
      theme.searchCard.hover.borderColor || 'transparent'};
  }
`;
const LabelContainer = styled.span`
  font-weight: ${svars.fontWeightMedium};
  padding-right: ${svars.spaceMedium};
  padding-bottom: ${svars.spaceSmall};
  text-align: left;
`;
const DescriptionContainer = styled.span`
  font-size: ${svars.fontSizeSmall};
  text-align: left;
  padding-bottom: ${svars.spaceNormal};
`;
const CategoryContainer = styled.span`
  float: right;
  text-align: right;
  align-self: flex-end;
  font-size: ${svars.fontSizeBase};
`;

function SearchCard({
  label,
  description,
  selected,
  categoryLabel,
  onClick,
  testid,
}) {
  const { commons } = commonConfigurationService.wording;
  return (
    <SearchCardContainer
      onClick={onClick}
      data-testid={testid}
      selected={selected}
    >
      <LabelContainer>{label}</LabelContainer>
      <DescriptionContainer>{description}</DescriptionContainer>
      <CategoryContainer>
        {commons.topic} : {categoryLabel}
      </CategoryContainer>
    </SearchCardContainer>
  );
}

SearchCard.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  categoryLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  testid: PropTypes.string,
};
SearchCard.defaultProps = {
  selected: false,
  testid: null,
};

export default SearchCard;
